<!-- 文章详情 -->
<template>
  <div class="details">
    <!-- 导航标签 -->
    <div class="flex biao align-items">
      <!-- <div>{{ "当前位置 : " }}</div>
      <div style="opacity: 0">{{ "0" }}</div>
      <div class="">{{ "首页" }}</div>
      <div class="zuojian">
        <img
          src="../../assets/img/xinban/heizuojian.png"
          class="w-full h-full"
          alt=""
        />
      </div>
      <div class="yanse">{{ "详情" }}</div> -->
    </div>
    <!-- 发布主题与撤回 -->
    <div class="chehui flex space-between align-items">
      <div class="flex">
        <el-button type="primary" @click="fabu">发表新帖</el-button>
        <el-button type="info" @click="huifa(wanzhang.usename, 0)"
          >跟帖评论</el-button
        >
      </div>
      <div class="flex align-items che cursor_pointer" @click="fanhui">
        <img src="../../assets/img/xinban/fanhui.png" class="huitui" alt="" />
        <div>{{ "返回列表" }}</div>
      </div>
    </div>
    <!-- 帖子详情 -->
    <div class="tiezi" v-if="mengnum==0">
      <!-- 详情 -->
      <div class="w-full xianq">
        <div class="w-full xian_head flex">
          <div class="flex justify-content align-items zuo"   :style="{backgroundImage: 'url(' + bg2 + ')' }" style="background-size: 100% 100%;">
            <div class="mr33 opacity">
              <span>{{ "查看 :" }}</span>
              <span class="opacity">我</span>
              <span class="yanse">{{
                wanzhang == null ? 0 : wanzhang.views
              }}</span>
            </div>
            <div class="flex opacity">
              <span>{{ "回复 :" }}</span>
              <span class="opacity">我</span>
              <span class="yanse">{{
                wanzhang == null ? 0 : wanzhang.comments
              }}</span>
              <!-- <div>{{}}</div> -->
            </div>
          </div>
          <div class="you flex1">
            <div>
              <span class="zixu">
                <span
                  class="zi"
                  v-if="wanzhang == null ? 0 : wanzhang.type == '咨询'"
                >
                  【{{ "咨询" }}】
                </span>
                <span
                  class="hong"
                  v-else-if="wanzhang == null ? 0 : wanzhang.type == '投诉'"
                >
                  【{{ "投诉" }}】
                </span>
                <span
                  class="lvz"
                  v-else-if="wanzhang == null ? 0 : wanzhang.type == '建议'"
                >
                  【{{ "建议" }}】
                </span>
                <span class="danwei">
                  【{{ wanzhang == null ? "" : wanzhang.unit_name }}】
                </span>
              </span>
              <img
                src="../../assets/img/one/img1.png"
                v-if="wanzhang == null ? '未审核' : wanzhang.status == '未审核'"
                class="shouli"
                alt=""
              />
              <img
                src="../../assets/img/one/img1.png"
                v-else-if="
                  wanzhang == null ? '未审核' : wanzhang.status == '待受理'
                "
                class="shouli"
                alt=""
              />
              <img
                src="../../assets/img/one/yishouli.png"
                v-else-if="
                  wanzhang == null ? '未审核' : wanzhang.status == '已受理'
                "
                class="shouli"
                alt=""
              />
              <img
                src="../../assets/img/one/yihuifu.png"
                v-else-if="
                  wanzhang == null ? '未审核' : wanzhang.status == '已回复'
                "
                class="shouli"
                alt=""
              />
              <img
                src="../../assets/img/one/img2.png"
                v-else-if="
                  wanzhang == null ? '未审核' : wanzhang.status == '已办结'
                "
                class="shouli"
                alt=""
              />
            </div>
            <div class="biaoti">
              {{ wanzhang == null ? "" : wanzhang.title }}
            </div>
            <!-- <img src="../../assets/img/xinban/yishouli.png" class="shouli" alt=""> -->
          </div>
        </div>
        <!-- 楼主 -->
        <div class="louzhu flex w-full">
          <div class="zuo flex">
            <!-- @click="yonghuqk(wanzhang.useid)" -->
            <div class="touxinag">
              <img
                v-if="wanzhang == null ? '' : wanzhang.useimage != ''"
                :src="
                  wanzhang == null
                    ? '../../assets/img/one/weizhiyonghu.png'
                    : wanzhang.useimage
                "
                class="w-full h-full"
                alt=""
              />
              <img
                v-else
                src="../../assets/img/one/weizhiyonghu.png"
                class="w-full h-full"
                alt=""
              />
            </div>
            <div
              class="ming white-space"
              :class="{ hz: wanzhang == null ? 0 : wanzhang.official == 1 }"
            >
              {{ wanzhang == null ? "" : wanzhang.usename }}
            </div>
            <!-- <div class="flex ziliao align-items">
              <div class="flex column align-items">
                <div>{{ wanzhang == null ? 0 : wanzhang.zhuti }}</div>
                <div class="fontone">{{ "主题" }}</div>
              </div>
              <div class="xian"></div>
              <div class="flex column align-items">
                <div>{{ wanzhang == null ? 0 : wanzhang.huitie }}</div>
                <div class="fontone">{{ "回帖" }}</div>
              </div>
            </div> -->
            <div class="flex align-items mt15">
              <div class="flex align-items">
                <div
                  class="flex align-items"
                  v-if="
                    wanzhang == null
                      ? '离线'
                      : wanzhang.userstatus_txt == '离线'
                  "
                >
                  <div class="dian"></div>
                  <div class="fontwo">{{ "离线" }}</div>
                </div>
                <div class="flex align-items" v-else>
                  <div class="dian lv"></div>
                  <div class="fontwo lvz">{{ "在线" }}</div>
                </div>
              </div>
              <div
                :class="{
                  opacity: wanzhang == null ? '0' : wanzhang.useid == uidb(),
                }"
              >
                <div
                  class="guanzhu"
                  v-if="wanzhang == null ? 0 : wanzhang.followtag == 0"
                  @click="guanz(wanzhang.useid, wanzhang.followtag)"
                >
                  {{ "未关注" }}
                </div>
                <div
                  class="yiguan"
                  v-else
                  @click="guanz(wanzhang.useid, wanzhang.followtag)"
                >
                  {{ "已关注" }}
                </div>
              </div>
            </div>
            <img
              src="../../assets/img/xinban/louzhu.png"
              class="louzhubiaoji"
              alt=""
            />
          </div>
          <div class="you flex1 flex column">
            <!-- 发布信息 -->
            <div class="fabuxinxi flex align-items space-between">
              <div class="fontone flex">
                {{
                  `发表于 ${wanzhang == null ? "昨天" : wanzhang.articletime} ${
                    wanzhang == null ? "PC端" : wanzhang.port
                  } 来自${wanzhang == null ? "" : wanzhang.province}${
                    wanzhang == null ? "" : wanzhang.district
                  }`
                }}
                <div class="flex wen">
                  <div class="mings">
                    <img
                      class="w-full h-full"
                      src="../../assets/img/xinban/biaoq.png"
                      alt=""
                    />
                  </div>
                  <div class="lan">{{ "文明实践" }}</div>
                </div>
              </div>
              <!-- <div class="fontTwo">{{ `编号${wanzhang == null ? '202304040002' : wanzhang.cardsalt}` }}</div> -->
              <div class="fontTwo">{{ "1楼" }}</div>
            </div>
            <!-- 文章主题 -->
            <div class="wenzhanzhuti flex">
              <div>
                <!-- html标签 -->
                <div
                  v-if="wanzhang == null ? '' : wanzhang.port == 'PC端'"
                  class="wenzhan"
                  v-html="wanzhang == null ? '' : wanzhang.content"
                ></div>
                <!-- 小程序展示 -->
                <div v-else>
                  <!-- 小程序文字内容 -->
                  <div v-html="wanzhang == null ? '' : wanzhang.content"></div>
                </div>

                <!-- 图片视频 -->
                <div style="margin-top: 20px">
                  <div v-if="wanzhang == null ? '' : wanzhang.port == '手机端'">
                    <!-- 图片展示 -->
                    <div class="tuzhan">
                      <div
                        class="tupian"
                        v-for="(item, index) in wanzhang == null
                          ? ''
                          : wanzhang.appletimages"
                        :key="index"
                      >
                        <div class="tunei">
                          <el-popover
                            placement="top-start"
                            title=""
                            popper-class="xuanfu"
                            width="50"
                            trigger="hover"
                          >
                            <div class="xiazai" @click="tuxiazia(item.url)">
                              {{ "下载" }}
                            </div>
                            <div class="tupianwen" slot="reference">
                              <span>{{ item.filename }}</span>
                              <span>({{ item.size }})</span>
                            </div>
                          </el-popover>
                          <el-image
                            class="img"
                            :src="item.url"
                            :preview-src-list="tuarr(wanzhang.appletimages)"
                          >
                          </el-image>
                        </div>
                      </div>
                    </div>
                    <!-- 小程序视频展示 -->
                    <div class="shizhan">
                      <div
                        class="shipins"
                        v-for="(item, index) in wanzhang == null
                          ? ''
                          : wanzhang.appletvideos"
                        :key="index"
                      >
                        <el-popover
                          placement="top-start"
                          title=""
                          popper-class="xuanfu"
                          width="50"
                          trigger="hover"
                        >
                          <div class="xiazai" @click="tuxiazia(item.url)">
                            {{ "下载" }}
                          </div>
                          <div class="tupianwen flex" slot="reference">
                            <span class="white-space">{{ item.filename }}</span>
                            <span class="white-space">({{ item.size }})</span>
                          </div>
                        </el-popover>
                        <div class="shipin">
                          <Videoplayer :mp4Url="item.url"></Videoplayer>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 附件 -->
                  <div
                    class="flex ful"
                    v-if="
                      wanzhang == null ? '' : wanzhang.appletfiles.length != 0
                    "
                  >
                    <div>{{ "附件 :" }}</div>
                    <div class="fuliebiao">
                      <div
                        @click="tuxiazia(item.url)"
                        class="wenjianming"
                        v-for="(item, index) in wanzhang == null
                          ? ''
                          : wanzhang.appletfiles"
                        :key="index"
                      >
                        <span class="hov">{{ item.filename }}</span>
                        <span class="xiazi">({{ item.size }})</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 官方回复 -->
                <div
                  class="flex w-full justify-content mt33"
                  v-if="wanzhang == null ? '' : wanzhang.huifutime != ''"
                >
                  <!-- <div class="guangfang">{{ `本主题由 ${'管理员'} 于 ${'7小时前'} 已回复` }}</div> -->
                  <div class="guangfang">
                    {{ wanzhang == null ? "" : wanzhang.huifutime }}
                  </div>
                </div>
              </div>
              <div class="flex caozuo">
                <div></div>
                <div class="flex align-items flex_end">
                  <!-- 收藏 -->
                  <div
                    class="bianju"
                    @click="
                      shouchang(wanzhang.cardsalt, wanzhang.collectiontag, 0)
                    "
                  >
                    <div class="flex align-items cursor_pointer">
                      <img
                        src="../../assets/img/xinban/shoucang.png"
                        v-if="
                          wanzhang == null ? 0 : wanzhang.collectiontag == 0
                        "
                        class="icon"
                        alt=""
                      />
                      <img
                        src="../../assets/img/one/yishoucnag.png"
                        v-else
                        class="icon"
                        alt=""
                      />
                      <div>{{ "收藏" }}</div>
                    </div>
                  </div>
                  <!-- 点赞 -->
                  <div
                    class="bianju"
                    @click="dianzan(wanzhang.cardsalt, wanzhang.liketag, 0)"
                  >
                    <div class="flex align-items cursor_pointer">
                      <img
                        src="../../assets/img/xinban/dainzan.png"
                        v-if="wanzhang == null ? 0 : wanzhang.liketag == 0"
                        class="icon"
                        alt=""
                      />
                      <img
                        src="../../assets/img/one/img19.png"
                        v-else
                        class="icon"
                        alt=""
                      />
                      <div>{{ "点赞" }}</div>
                    </div>
                  </div>
                  <!-- 举报 -->
                  <div
                    class="bianju"
                    @click="jubao(wanzhang.cardsalt, 0, wanzhang.usename)"
                  >
                    <div class="flex align-items cursor_pointer">
                      <img
                        src="../../assets/img/xinban/jubao.png"
                        class="icon"
                        alt=""
                      />
                      <div class="yanse">{{ "举报" }}</div>
                    </div>
                  </div>
                  <!-- 楼层 -->
                  <!-- <div class="bianju">
                    <div class="cursor_pointer">{{ '1' }}楼</div>
                                        </div> -->
                  <!-- 回复 -->
                  <div class="bianju">
                    <div
                      class="cursor_pointer yanse"
                      @click="huifa(wanzhang.usename, 0)"
                    >
                      {{ "回复" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 其他楼层 -->
      <div
        class="louzhu flex w-full"
        v-for="(item, index) in pingList"
        :key="index"
        :class="{ act: index + 1 == pingList.length }"
      >
        <div class="louzhu flex w-full">
          <div class="zuo flex">
            <div
              class="touxinag"
              @click="yonghuqk(item.handle_id, item.official)"
            >
              <img
                v-if="pingList.length == 0 ? '' : item.usehdimage != ''"
                :src="
                  pingList.length == 0
                    ? '../../assets/img/one/weizhiyonghu.png'
                    : item.usehdimage
                "
                class="w-full h-full"
                alt=""
              />
              <img
                v-else
                src="../../assets/img/one/weizhiyonghu.png"
                class="w-full h-full"
                alt=""
              />
            </div>
            <div class="ming" :class="{ hz: item.official == 1 }">
              {{ pingList.length == 0 ? "" : item.usehdname
              }}<span v-if="item.official == 1">网络发言人</span>
            </div>
            <!-- <div class="flex ziliao align-items">
              <div class="flex column align-items">
                <div>{{ item.hdzhuti }}</div>
                <div class="fontone">{{ "主题" }}</div>
              </div>
              <div class="xian"></div>
              <div class="flex column align-items">
                <div>{{ item.hdhuitie }}</div>
                <div class="fontone">{{ "回帖" }}</div>
              </div>
            </div> -->
            <div class="flex align-items mt15" v-if="item.official != 1">
              <div class="flex align-items">
                <div
                  class="flex align-items"
                  v-if="item.userstatus_txt == '离线'"
                >
                  <div class="dian"></div>
                  <div class="fontwo">{{ "离线" }}</div>
                </div>
                <div class="flex align-items" v-else>
                  <div class="dian lv"></div>
                  <div class="fontwo lvz">{{ "在线" }}</div>
                </div>
              </div>
              <div :class="{ opacity: item.handle_id == uidb() }">
                <div
                  class="guanzhu"
                  v-if="pingList.length == 0 ? 0 : item.followtag == 0"
                  @click="guanz(item.handle_id, item.followtag)"
                >
                  {{ "未关注" }}
                </div>
                <div
                  class="yiguan"
                  v-else
                  @click="guanz(item.handle_id, item.followtag)"
                >
                  {{ "已关注" }}
                </div>
              </div>
            </div>
            <img
              src="../../assets/img/xinban/louzhu.png"
              v-if="item.owntag == 1"
              class="louzhubiaoji"
              alt=""
            />
            <img
              src="../../assets/img/xinban/fyr.png"
              v-if="item.official == 1"
              class="fyrbiaoji"
              alt=""
            />
          </div>
          <div class="you flex1 flex column">
            <!-- 发布信息 -->
            <div class="fabuxinxi flex align-items space-between">
              <div class="fontone">
                {{
                  `发表于 ${pingList.length == 0 ? "" : item.cmtime} ${
                    pingList.length == 0 ? "PC端" : item.port
                  } 来自${pingList.length == 0 ? "" : item.province}${
                    pingList.length == 0 ? "" : item.district
                  }`
                }}
              </div>
              <!-- <div class="fontTwo">{{ `编号${pingList.length == 0 ? "" : item.cardsaltpid}` }}</div> -->
              <div class="fontTwo">{{ louceng(index) }}</div>
            </div>
            <!-- 文章主题 -->
            <div class="wenzhanzhuti flex">
              <div>
                <!-- html标签 -->
                <div
                  class="wenzhan"
                  v-html="
                    pingList.length == 0 ? '<div></div>' : wenguo(item.content)
                  "
                ></div>
                <!-- 评论图片 -->
                <div v-if="item.port == '手机端'">
                  <div class="pint" v-if="item.images != ''">
                    <el-image
                      class="pingtu"
                      :src="item.images"
                      :preview-src-list="pintuarr(index)"
                    >
                    </el-image>
                  </div>
                </div>
              </div>
              <div class="flex caozuo">
                <!-- 保持两边平衡 -->
                <div class="fujian">
                  <!-- group_id是否大于3 -->
                  <div v-if="item.group_id >= 3">
                    <!-- 数组长度是否为0 -->
                    <div class="fujian" v-if="item.replyfiles.length != 0">
                      <!-- 数组是否为null -->
                      <div class="flex" v-if="item.replyfiles != null">
                        <div class="fu">{{ "附件 :" }}</div>
                        <div class="papazuo">
                          <!-- <div class="lou" v-for="(res, index) in item.replyfiles" :key="index"
                          @click="taiochuwuxing(res)">{{ res.filename }}</div> -->
                          <div
                            class="lou"
                            v-for="(res, index) in item.replyfiles"
                            :key="index"
                          >
                            <!-- 图片预览 -->
                            <div v-if="fujian(res.url) == 0" class="flex">
                              <div class="tufu">
                                <el-image
                                  class="fugai"
                                  :src="res.url"
                                  :preview-src-list="tup(res.url)"
                                >
                                </el-image>
                                {{ res.filename == "" ? `图片` : res.filename }}
                              </div>
                              <div
                                class="xiabtn"
                                @click="taiochuwuxing(res.url)"
                              >
                                {{ "下载" }}
                              </div>
                            </div>
                            <div v-else-if="fujian(res.url) == 1" class="flex">
                              <div class="tufu">
                                <el-image
                                  class="fugai"
                                  :src="res.url"
                                  :preview-src-list="tup(res.url)"
                                >
                                </el-image>
                                {{ res.filename == "" ? `视频` : res.filename }}
                              </div>
                              <div class="xiabtn" @click="bofangs(res.url)">
                                {{ "播放" }}
                              </div>
                            </div>
                            <!-- pdf预览 -->
                            <div v-else-if="fujian(res.url) == 1" class="flex">
                              <div
                                class="pdffu"
                                @click="taiochuwuxing(res.url)"
                              >
                                {{ res.filename }}
                              </div>
                              <!-- <div class="xiabtn" @click="taiochuwuxing(res.url)">{{ '下载' }}</div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex align-items flex_end w-full">
                  <!-- 点赞 -->
                  <div
                    class="bianju"
                    @click="
                      pingDianzan(item.cardsalt, item.liketag, 1, 0, index)
                    "
                  >
                    <div class="flex align-items cursor_pointer">
                      <img
                        src="../../assets/img/xinban/dainzan.png"
                        v-if="pingList.length == 0 ? 0 : item.liketag == 0"
                        class="icon"
                        alt=""
                      />
                      <img
                        src="../../assets/img/one/img19.png"
                        v-else
                        class="icon"
                        alt=""
                      />
                      <div>{{ "点赞" }}</div>
                    </div>
                  </div>
                  <!-- 举报 -->
                  <div
                    class="bianju"
                    @click="jubao(item.cardsalt, 1, item.usehdname)"
                  >
                    <div class="flex align-items cursor_pointer">
                      <img
                        src="../../assets/img/xinban/jubao.png"
                        class="icon"
                        alt=""
                      />
                      <div class="yanse">{{ "举报" }}</div>
                    </div>
                  </div>
                  <!-- 回复 -->
                  <div class="bianju">
                    <div
                      class="cursor_pointer yanse"
                      @click="kaicihui(index, item.childcount)"
                    >
                      {{ "回复" }}
                      <span v-if="item.child.length != 0"
                        >({{ item.child.length }})</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 次级回复 -->
                <div>
                  <div class="cijihuifu" v-if="item.kaihe != 0">
                    <div>
                      <!-- 内容 -->
                      <div style="margin-top: 10px">
                        <!-- 次级回复模板 -->
                        <div
                          class="cihang"
                          v-for="(res, num) in item.child"
                          :key="num"
                        >
                          <!-- 左边头像 -->
                          <div class="touxiang">
                            <img :src="res.usehdimage" alt="" />
                          </div>
                          <!-- 右边回复 -->
                          <div class="flex1">
                            <div div class="w-full">
                              <span class="ciming">{{ res.usehdname }} :</span>
                              <span
                                v-if="res.usename != ''"
                                class="ciming"
                                style="color: #000"
                                >回复</span
                              >
                              <span class="ciming" v-if="res.usename != ''"
                                >{{ res.usename }} :</span
                              >
                              <span class="cihuinei" v-html="res.content"></span>
                              <!-- <span class="cihuinei">{{ res.content }}</span> -->
                              <div class="xiaoshijian">
                                {{
                                  `发表于 ${res.cmtime} ${res.port} 来自${res.province}${res.district}`
                                }}
                              </div>
                            </div>
                            <div class="xibain">
                              <div class="flex align-items">
                                <!-- 点赞 -->
                                <div
                                  class="bianju"
                                  @click="
                                    pingDianzan(
                                      res.cardsalt,
                                      res.liketag,
                                      1,
                                      1,
                                      index,
                                      num
                                    )
                                  "
                                >
                                  <div class="flex align-items cursor_pointer">
                                    <img
                                      src="../../assets/img/xinban/dainzan.png"
                                      v-if="res.liketag == 0"
                                      class="icon"
                                      alt=""
                                    />
                                    <img
                                      src="../../assets/img/one/img19.png"
                                      v-else
                                      class="icon"
                                      alt=""
                                    />
                                    <div>{{ "点赞" }}</div>
                                  </div>
                                </div>
                                <!-- 举报 -->
                                <div
                                  class="bianju"
                                  @click="jubao(res.cardsalt, 1, res.usehdname)"
                                >
                                  <div class="flex align-items cursor_pointer">
                                    <img
                                      src="../../assets/img/xinban/jubao.png"
                                      class="icon"
                                      alt=""
                                    />
                                    <div class="yanse">{{ "举报" }}</div>
                                  </div>
                                </div>
                                <!-- 回复 -->
                                <div class="bianju">
                                  <div
                                    class="cursor_pointer yanse"
                                    @click="
                                      cihuitan(
                                        res.usehdname,
                                        res.cardsalt,
                                        index
                                      )
                                    "
                                  >
                                    {{ "回复" }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 更多加载 -->
                      <div class="gengduo">
                        <div>
                          <div v-if="item.childcount - item.child.length != 0">
                            还有{{ item.childcount - item.child.length }}条回复
                            ,
                            <span
                              style="color: #2d64b3"
                              @click="gengduoci(index, item.cardsalt)"
                              >点击查看</span
                            >
                          </div>
                        </div>
                        <div>
                          <div v-if="item.childcount != 0">
                            <div class="shuobtn" @click="woshuo(index)">
                              我也说一句
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="item.huifu.num == 0">
                        <!-- 输入框 -->
                        <el-input
                          type="textarea"
                          autosize
                          placeholder="请输入内容"
                          v-model="item.wenben"
                          resize="none"
                          @input="onInput()"
                        >
                        </el-input>
                        <!-- 确认按钮 -->
                        <div class="w-full flex align-items space-between">
                          <div @click="pingnum(index)" class="flex align-items">
                            <div>
                              <el-dropdown>
                                <div class="jizhuduxiang">@</div>
                                <el-dropdown-menu slot="dropdown">
                                  <el-dropdown-item
                                    v-for="(item, index) in buarr"
                                    :key="index"
                                    ><div @click="handleClick(item.name)">
                                      {{ item.name }}
                                    </div></el-dropdown-item
                                  >
                                </el-dropdown-menu>
                              </el-dropdown>
                            </div>
                          </div>
                          <div class="jianfei flex flex1 flex_wrap">
                            <div
                              class="antebieren"
                              v-for="(res, num) in item.buarr"
                              :key="num"
                            >
                              {{ res }}
                              <i
                                @click="shanping(index, res)"
                                class="el-icon-close gbclos"
                              ></i>
                            </div>
                          </div>
                          <div
                            class="fabtn"
                            @click="cihui(index, item.cardsalt)"
                          >
                            发表
                          </div>
                        </div>
                      </div>
                      <!-- 次级回复专用输入框 -->
                      <div v-else>
                        <!-- 输入框 -->
                        <el-input
                          type="textarea"
                          autosize
                          :placeholder="item.huifu.name"
                          v-model="item.huifu.wenben"
                          resize="none"
                        >
                        </el-input>
                        <!-- 确认按钮 -->
                        <div class="w-full flex align-items space-between">
                          <div @click="pingnum(index)" class="flex align-items">
                            <div>
                              <el-dropdown>
                                <div class="jizhuduxiang">@</div>
                                <el-dropdown-menu slot="dropdown">
                                  <el-dropdown-item
                                    v-for="(item, index) in buarr"
                                    :key="index"
                                    ><div @click="handleClick(item.name)">
                                      {{ item.name }}
                                    </div></el-dropdown-item
                                  >
                                </el-dropdown-menu>
                              </el-dropdown>
                            </div>
                          </div>
                          <div class="jianfei flex flex1 flex_wrap">
                            <div
                              class="antebieren"
                              v-for="(res, num) in item.buarr"
                              :key="num"
                            >
                              {{ res }}
                              <i
                                @click="shanping(index, res)"
                                class="el-icon-close gbclos"
                              ></i>
                            </div>
                          </div>
                          <div class="fabtn" @click="gengcihui(index)">
                            发表
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页码 -->
    <div class="yema" v-if="total > 10">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :page-sizes="[10, 20]"
        :page-size="100"
        layout=" prev, pager, next, sizes, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 线 -->
    <div class="xian"></div>
    <!-- 富文本 -->
    <div class="flex fwb">
      <div class="huifu">{{ "发表回复 : " }}</div>
      <div class="wb">
        <div class="w-full wangd" @click="token == undefined?pinglun():''">
          <wangEditor
            v-model="fuwenben"
            :key="cishu"
            :value="fwb"
            ref="editor"
          ></wangEditor>
          <!-- <div class="weideng" v-if="token == undefined">
            <div class="fontone">
              您需要登录后才可以回帖
              <span class="cursor_pointer" @click="dengfatie">登录</span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="w-full flex" style="margin-bottom: 11px">
      <div class="heqiang"></div>
      <div class="btn">
        <el-button type="primary" :disabled="!canfabu" @click="pinglun">发表</el-button>
      </div>
    </div>
    <!-- 可移动弹框 -->
    <div class="yi" v-if="fayan == true">
      <!-- 标题 -->
      <div class="yidongquyu flex" @click.left="touchmove">
        <div class="flex">
          <div>{{ "回复" }}</div>
          <div>{{ beihuifuzhe }}</div>
        </div>
        <div class="guanbi" @click="kaiguan">
          <img
            src="../../assets/img/xinban/close-circle.png"
            class="w-full h-full"
            alt=""
          />
        </div>
      </div>
      <!-- 回复 -->
      <ciji ref="ciji"></ciji>
    </div>
    <!-- 举报弹框 -->
    <el-dialog :visible.sync="dialogVisible">
      <ju ref="ju"></ju>
    </el-dialog>
    <!-- 上传图片弹框 -->
    <div class="shangchua" v-if="benstu != 0">
      <!-- 卡片头部 -->
      <div class="flex align-items tou space-between">
        <div class="h-full">
          <div class="flex fuqilai">
            <div class="shangchuan meixia">{{ "上传图片" }}</div>
          </div>
        </div>
        <!-- 关闭开关 -->
        <div @click="qingkongt" class="closeg el-icon-error"></div>
      </div>
      <!-- 卡片内容 -->
      <div class="shenti">
        <!-- 上传图片 -->
        <div class="tupianka">
          <el-upload
            :show-file-list="false"
            ref="upload"
            class="upload-demo"
            :action="$store.state.uploadurl + '/api/common/upload'"
            :headers="huotoken()"
            :multiple="false"
            :on-success="lst"
            :before-upload="tucha"
          >
            <el-button size="small" type="primary">{{
              "选择图片上传"
            }}</el-button>
            <span class="yangshi">
              <span>{{ "图片大小:" }}</span>
              <span class="zy">{{ "小于5M" }}</span>
              <span>,{{ "可上传类型:" }}</span>
              <span class="zy">{{ " jpg, jpeg, gif, png,webp,jfif " }}</span>
            </span>
          </el-upload>
          <!-- <div class="hbss"></div> -->
          <!-- 图片展示 -->
          <div class="flex kazhan">
            <!-- 模板 -->
            <div v-for="(item, index) in pingtu" :key="index" class="katu">
              <div class="tubufen" @click="cahru(item)">
                <img :src="item" class="w-full" alt="" />
              </div>
              <div class="tushan" @click="closepingtu(index)">{{ "删除" }}</div>
            </div>
          </div>
        </div>
        <!-- 确定按钮 -->
        <div class="dibuka">
          <div class="jinggao beizhu">备注: 点击图片,将图片插入富文本</div>
          <div class="kabtn" @click="guantan">{{ "确定" }}</div>
        </div>
      </div>
    </div>
    <!-- 次级评论图片上传弹框 -->
    <div class="shangchua" v-if="cistu != 0">
      <!-- 卡片头部 -->
      <div class="flex align-items tou space-between">
        <div class="h-full">
          <div class="flex fuqilai">
            <div class="shangchuan meixia">{{ "上传图片" }}</div>
          </div>
        </div>
        <!-- 关闭开关 -->
        <div @click="ciqing" class="closeg el-icon-error"></div>
      </div>
      <!-- 卡片内容 -->
      <div class="shenti">
        <!-- 上传图片 -->
        <div class="tupianka">
          <el-upload
            :show-file-list="false"
            ref="upload"
            class="upload-demo"
            :action="$store.state.uploadurl + '/api/common/upload'"
            :headers="huotoken()"
            :multiple="false"
            :on-success="cilst"
            :before-upload="tucha"
          >
            <el-button size="small" type="primary">{{
              "选择图片上传"
            }}</el-button>
            <span class="yangshi">
              <span>{{ "图片大小:" }}</span>
              <span class="zy">{{ "小于5M" }}</span>
              <span>,{{ "可上传类型:" }}</span>
              <span class="zy">{{ " jpg, jpeg, gif, png,webp,jfif " }}</span>
            </span>
          </el-upload>
          <!-- <div class="hbss"></div> -->
          <!-- 图片展示 -->
          <div class="flex kazhan">
            <!-- 模板 -->
            <div v-for="(item, index) in cipngtu" :key="index" class="katu">
              <div class="tubufen" @click="chatwo(item)">
                <img :src="item" class="w-full" alt="" />
              </div>
              <div class="tushan" @click="closecipingtu(index)">
                {{ "删除" }}
              </div>
            </div>
          </div>
        </div>
        <!-- 确定按钮 -->
        <div class="dibuka">
          <div class="jinggao beizhu">备注: 点击图片将图片插入富文本</div>
          <div class="kabtn" @click="ciguantan">{{ "确定" }}</div>
        </div>
      </div>
    </div>
    <!-- 遮罩层 -->
    <div class="zhe" v-if="fayan == true"></div>
    <botan ref="bofa" :url="sdizhi" v-if="bonum != 0"></botan>
    <!-- 蒙层 -->
    <div class="mengcneg" v-if="mengnum != 0"></div>
    <!-- 弹框 -->
    <div class="tankuang" v-if="mengnum != 0">
      <div class="zhonjian">
        {{ ` 该条处于(${muqian}),只能本人查看,他人不能查看详情!` }}
      </div>
      <!-- <el-button type="primary" round>确定</el-button> -->
    </div>
  </div>
</template>

<script>
// 引入富文本
import Bg2 from '@/assets/img/xinban/tztopbg.png'
import wangEditor from "./wangEditor/index.vue";
import API from "@/api/modules/details.js";
import Cookies from "js-cookie";
import ju from "./jujian/index.vue"; // 举报弹框
import ciji from "./ciji/index.vue"; //回复弹框
import Bus from "@/assets/ligature.js";
import Videoplayer from "../video/index.vue"; // 视频播放组件
import botan from "../bofang/index.vue"; // 播放弹框
import { MessageBox } from 'element-ui'
var timess;
export default {
  components: {
    wangEditor,
    ciji,
    ju,
    Videoplayer,
    botan,
  },
  data() {
    return {
      bg2: Bg2,
      pinglunsuo: false, //pinglun方法的互斥锁
      total: 0,
      cishu: 0, //富文本清空
      bjq: "", // 编辑器内容
      fuwenben: "",
      fwb: "",
      wanzhang: null,
      token: undefined, //token状态
      pingList: [],
      page: 1,
      zongye: 10,
      fayan: false, //回复弹框
      beihuifuzhe: "",
      cijiid: "", //次级评论的id
      dialogVisible: false, //举报弹框
      zid: "", //作者id
      benstu: 0, //富文本弹框
      pingtu: [], //评论帖子的富文本弹框
      cistu: 0, //次级评论富文本弹框
      cipngtu: [], //次级评论的图片数组
      loading: null,
      bonum: 0, //播放弹框
      sdizhi: "", //视频地址
      muqian: "", //为什么蒙层
      mengnum: 0, //打开蒙层
      canfabu:true,
    };
  },
  mounted() {
    // 判断url有无数据,没有返回首页
    let q = {};
    // 获取来自网址连接的信息
    let test = window.location.href;
    test.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (q[k] = v));
    if (q.cardsalt == undefined) {
      this.$router.push("/");
      return;
    } else {
      this.$store.state.loginMessage.cardsalt = q.cardsalt;
    }
    if(this.$store.state.mytie.fuwenben){
      this.fuwenben=this.$store.state.mytie.ruleForm
    }
    window.scrollTo(0, 0);
    // 开启监听
    this.bendij();
    sessionStorage.setItem("pingstu", 0);
    sessionStorage.setItem("cistu", 0);
    localStorage.setItem("path", this.$route.path);
    // window.localStorage.setItem('sendebang', a)
    let aaa = window.localStorage.getItem("sendebang");
    // window.localStorage.removeItem('sendebang')
    this.token = aaa;
    this.tiezi();
    this.artCommetList();
    document.getElementsByClassName(
      "el-pagination__jump"
    )[0].childNodes[0].nodeValue = "跳至";
  },
  created() {
    Bus.$on("guanbis", () => {
      this.closes();
    });
  },
  methods: {
    // 删除安特人
    shanping(num, res) {
      let b = this.pingList[this.pingindex].buarr.indexOf(res);
      this.pingList[this.pingindex].buarr.splice(b, 1);
      let a = this.pingList;
      this.pingList = [];
      this.pingList = a;
    },
    // 获取评论下标
    pingnum(num) {
      this.pingindex = num;
    },
    // @下拉菜单
    handleClick(e) {
      if (this.pingList[this.pingindex].huifu.num == 0) {
        let b = this.pingList[this.pingindex].wenben;
        this.buarr.forEach((item) => {
          if (e == item.name) {
            b = `${b} @${item.name} `;
            // this.pingList[this.pingindex].buarr.push(item.name);
          }
        });
        this.pingList[this.pingindex].wenben = "";
        this.pingList[this.pingindex].wenben = b;
      } else {
        let b = this.pingList[this.pingindex].huifu.wenben;
        this.buarr.forEach((item) => {
          if (e == item.name) {
            b = `${b} @${item.name} `;
            // this.pingList[this.pingindex].buarr.push(item.name);
          }
        });
        this.pingList[this.pingindex].huifu.wenben = "";
        this.pingList[this.pingindex].huifu.wenben = b;
      }

      let a = this.pingList;
      this.pingList = [];
      this.pingList = a;
    },
    // 评论图片预览
    pintuarr(num) {
      let a = [];
      a.push(this.pingList[num].images);
      return a;
    },
    // 关闭视频播放
    shiclose() {
      this.bonum = 0;
    },
    // 播放视频
    bofangs(url) {
      this.sdizhi = url;
      this.bonum = 1;
    },
    // 复制的样式适配
    wenguo(str) {
      return str;
      var regex2 = new RegExp("(i?)(<*?style=['\"])([^>]+>)", "gmi");
      //在img标签的style里面增加css样式(这里增加的样式：display:block;max-width:100%;height:auto;border:5px solid red;)
      let htmlstr = str.replace(
        regex2,
        "$2max-width:870px;box-sizing:border-box;$3"
      );
      return htmlstr;
    },
    onInput() {
      this.$forceUpdate();
    },
    // 回复层主
    woshuo(num) {
      let a = JSON.parse(JSON.stringify(this.pingList));
      a[num].huifu.name = "";
      a[num].huifu.id = "";
      a[num].huifu.num = 0;
      a[num].buarr = [];
      this.pingList = [];
      this.pingList = a;
    },
    // 更次级回复
    async gengcihui(num) {
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });

      let aa = this.pingList[this.pingindex].huifu.wenben;
      let bb = [];

      this.buarr.forEach((item) => {
					let target = `@${item.name}`;
					var contains = aa.includes(target)
					if (contains == true) {
						bb.push(item.id);
					}
			});

      let res = API.artComment({
        cardid: this.$store.state.loginMessage.cardsalt,
        commentid: this.pingList[num].huifu.id,
        departid: bb.toString(),
        port: 0,
        content: this.pingList[num].huifu.wenben,
      })
        .then((res) => {
          if (res.code == 1) {
            this.loading.close();
            this.$message({
              message: "发送成功,正在审核中!",
              center: true,
              type: "success",
            });
            this.pingList[num].huifu.wenben = "";
            this.pingList[num].buarr = [];
          } else {
            this.loading.close();
            this.$message({
              message: res.msg,
              center: true,
              type: "error",
            });
          }
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    // 次级回复弹框
    // name:被回复人的姓名
    // id:被回复人的id,
    // num:切换那个主列表的输入框
    cihuitan(name, id, num) {
      let a = JSON.parse(JSON.stringify(this.pingList));
      a[num].huifu.name = "回复" + name;
      a[num].huifu.id = id;
      a[num].huifu.num = 1;
      this.pingList = [];
      this.pingList = a;
    },
    // 评论列表(小程序)
    async artCommetList() {
      let res = await API.artCommetListwechat({
        cardid: this.$store.state.loginMessage.cardsalt,
        commentid: "",
        page: this.page,
        pageLine: this.zongye,
      }).then((res) => {
        if (res.code == 1) {
          this.pingList = res.data.list;
          this.pingList.forEach((item) => {
            if (item.childcount > 0) {
              // 是否开合
              item.kaihe = 1;
            } else {
              // 是否开合
              item.kaihe = 0;
            }
            // 当前部门是否@了部门
            item.buarr = [];
            // 当前输入框内容
            item.wenben = "";
            // 当前页码
            item.page = 0;
            // 次级回复
            item.huifu = {
              name: "",
              id: "",
              wenben: "",
              num: 0,
            };
          });
          // this.pingList.forEach(item => {
          //   item.child.forEach(res => {
          //     res.wenben = ''

          //   })
          // })
          // let a = res.data.list;
          // console.log(this.pingList, "pingList");
          this.total = res.data.total;
        } else {
          this.$message({
            message: re.msg,
            type: "warning",
          });
        }
      });
    },
    async gengduoci(num, id) {
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      // 页码加1
      this.pingList[num].page++;
      let res = await API.artCommetListwechat({
        cardid: this.$store.state.loginMessage.cardsalt,
        commentid: id,
        page: this.pingList[num].page,
        pageLine: 10,
      })
        .then((res) => {
          if (res.code == 1) {
            // 页码为1的时候要先清空列表页自带的内容
            if (this.pingList[num].page == 1) {
              this.pingList[num].child = [];
              this.pingList[num].child = res.data.list;
            } else {
              res.data.list.forEach((item) => [
                this.pingList[num].child.push(item),
              ]);
            }
            this.loading.close();
          } else {
            this.loading.close();
            this.$message({
              message: res.msg,
              center: true,
              type: "error",
            });
          }
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    // 新版次级回复
    async cihui(num, id) {
      if(!this.canfabu){
      return
     }
     this.canfabu=false
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });

      let aa = this.pingList[this.pingindex].wenben;
      let bb = [];

      this.buarr.forEach((item) => {
					let target = `@${item.name}`;
					var contains = aa.includes(target)
					if (contains == true) {
						bb.push(item.id);
					}
			});

      let res = API.artComment({
        cardid: this.$store.state.loginMessage.cardsalt,
        commentid: id,
        departid: bb.toString(),
        port: 0,
        content: this.pingList[num].wenben,
      })
        .then((res) => {
          this.canfabu=true
          if (res.code == 1) {
            this.loading.close();
            this.$message({
              message: "发送成功,正在审核中!",
              center: true,
              type: "success",
            });
            this.pingList[num].wenben = "";
            this.pingList[num].buarr = [];
            let d = this.pingList;
            this.pingList = [];
            this.pingList = d;
          } else {
            this.loading.close();
            this.$message({
              message: res.msg,
              center: true,
              type: "error",
            });
          }
        })
        .finally((suo) => {
          this.canfabu=true
          // 都执行
          this.loading.close();
        });
    },
    kaicihui(num) {
      let a = JSON.parse(JSON.stringify(this.pingList));
      this.pingList = [];
      if (a[num].kaihe == 0) {
        a[num].kaihe = 1;
      } else {
        a[num].kaihe = 0;
      }
      this.pingList = a;
    },
    // 判断是否有top提示
    gf(str) {
      let a = str.split(",");
      if (a.length > 2) {
        return false;
      } else {
        return true;
      }
    },
    // 部门长度
    buchandu(str) {
      let a = str.split(",");
      if (a.length <= 2) {
        return a.toString();
      } else {
        return a[0] + "," + a[1] + "...";
      }
    },
    // 次级评论将图片插入文中
    chatwo(url) {
      // return
      this.$refs.ciji.chaone(url);
    },
    // 删除次评的图片
    closecipingtu(num) {
      this.pingtu.splice(cipngtu, 1);
    },
    // 次级评论上传
    cilst(res) {
      if (res.code == 1) {
        // 占时只允许上传一张
        // this.cipngtu = [];
        this.cipngtu.push(res.data.fullurl);
      } else {
        this.$message.error(res.msg);
      }
    },
    ciqing() {
      sessionStorage.setItem("cistu", 0);
      this.cistu = 0;
      this.cipngtu = [];
      this.$refs.ciji.benditan();
    },
    // 关闭次级上传弹框,并开始重新监听
    ciguantan() {
      sessionStorage.setItem("cistu", 0);
      this.cistu = 0;
      // this.chatwo(this.cipngtu[0]);
      this.$refs.ciji.benditan();
    },
    // 次级评论打开弹框
    cipingtan() {
      this.cistu = 1;
    },
    // 将图片插入富文本
    cahru(url) {
      // return
      this.$refs.editor.charutu(url);
    },
    // 删除评论图片
    closepingtu(num) {
      this.pingtu.splice(num, 1);
    },
    // 上传时得到token
    huotoken() {
      // window.localStorage.setItem('sendebang', a)
      let aaa = window.localStorage.getItem("sendebang");
      // window.localStorage.removeItem('sendebang')
      return { token: aaa };
    },
    // 在卡片里图片上传成功
    lst(res) {
      // console.log(res, '上chaun');
      if (res.code == 1) {
        // 占时只允许上传一张
        // this.pingtu = [];
        this.pingtu.push(res.data.fullurl);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 图片上传前的校验
    tucha(e) {
      const isSize = e.size / 1024 / 1024;
      if (isSize > 5) {
        this.$message.error("图片大小不能超过5M！");
        return false;
      }
      let a = e.name;
      const isExcel =
        a.split(".").pop() === "jpg" ||
        a.split(".").pop() === "jpeg" ||
        a.split(".").pop() === "gif" ||
        a.split(".").pop() === "webp" ||
        a.split(".").pop() === "jfif" ||
        a.split(".").pop() === "png";
      if (!isExcel) {
        this.$message.error("只能上传jpg,jpeg,gif,png,webp,jfif类型的图片!");
        return false;
      }
    },
    qingkongt() {
      sessionStorage.setItem("pingstu", 0);
      this.pingtu = [];
      this.bendij();
    },
    // 关闭上传弹框,并开始重新监听
    guantan() {
      sessionStorage.setItem("pingstu", 0);
      // this.cahru(this.pingtu[0]);
      this.bendij();
    },
    // 监听本地存储,最蠢的方法,我暂时没有更好的想法(2023/6/10)
    bendij() {
      // 将它异步
      let that = this;
      timess = setTimeout(() => {
        that.benstu = sessionStorage.getItem("pingstu");
        if (that.benstu == 0) {
          that.bendij();
        } else {
        }
      }, 100);
    },
    // 返回回复内容
    huifunei(num) {
      return this.pingList[num].p_content;
    },
    // 将时间返回
    huishi(num) {
      return this.pingList[num].usecomtime;
    },
    // 评论图片预览
    pintuarr(num) {
      let a = [];
      let obj = this.pingList[num].replyfiles;
      obj.forEach((item) => {
        a.push(item.url);
      });
      return a;
    },
    // 图片下载
    tuxiazia(url) {
      const fileUrl = url;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "your-file.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 图片数组
    tuarr(obj) {
      let a = [];
      obj.forEach((item) => {
        a.push(item.url);
      });
      return a;
    },
    // 预览图片
    tup(url) {
      let a = [];
      a.push(url);
      return a;
    },
    // 附件类型判断
    fujian(url) {
      let a = url.split(".").pop();
      a = a.split("?");
      a = a[0];
      if (a.split(".").pop() == "png") {
        return 0;
      }
      if (a.split(".").pop() == "gif") {
        return 0;
      }
      if (a.split(".").pop() == "jpg") {
        return 0;
      }
      if (a.split(".").pop() == "jpeg") {
        return 0;
      }
      return 1;
      // if(isExcel == 'pdf'){
      //   return 1
      // }
    },
    // 楼层
    louceng(num) {
      return (this.page - 1) * 10 + num + 2 + "楼";
    },
    // 跳附件
    taiochuwuxing(url) {
      window.open(url, "_blank");
    },
    uidb() {
      let a = localStorage.getItem("uid");
      return a;
    },
    // 其他用户
    yonghuqk(id, num) {
      return;
      if (num == 1) {
        return;
      }
      // console.log(id == this.$store.state.loginMessage.uid, 'sss--');
      // console.log(this.$store.state.loginMessage.uid,'222==');
      // 记录id
      this.$store.state.loginMessage.id = id;
      // 判断此id是否等于自己的id
      // console.log(localStorage.getItem('uid'),'2222');
      if (id == localStorage.getItem("uid")) {
        this.jumps("persomal", this.$store.state.loginMessage);
      } else {
        this.jumps("other", this.$store.state.loginMessage);
      }
    },
    // 关闭举报弹框
    closes() {
      this.dialogVisible = false;
    },
    // 举报弹框
    async jubao(id, type, nams) {
      // window.localStorage.setItem('sendebang', a)
      let aaa = window.localStorage.getItem("sendebang");
      // window.localStorage.removeItem('sendebang')
      if (aaa == undefined) {
        this.$confirm("当前未登录,是否转到登录页!", "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
              query: {},
            });
          })
          .catch((err) => {});
        return;
      }
      this.dialogVisible = true;
      let timer = setTimeout(() => {
        Bus.$emit("sj", id, type, nams);
      }, 500);
    },
    // 关闭回复弹框
    kaiguan() {
      this.fayan = false;
    },
    // 打开回复弹框
    huifa(name, id) {
      // 先判定是否有token 如果为空
      // window.localStorage.setItem('sendebang', a)
      let aaa = window.localStorage.getItem("sendebang");
      // window.localStorage.removeItem('sendebang')
      if (aaa == undefined) {
        this.$store.state.path = this.$route.path;
        localStorage.setItem("path", this.$route.path);
        // this.$router.push('/login')
        this.jump("login", this.$store.state.loginMessage);
        return;
      }
      if (id == 0) {
        this.cijiid = "";
      } else {
        this.cijiid = id;
      }
      this.beihuifuzhe = name;
      this.fayan = true;
      // 打开回复弹框时关闭监听回复帖子的富文本弹框监视
      clearTimeout(timess);
      sessionStorage.setItem("pingstu", 0);
      this.benstu = 0;
    },
    // 获取鼠标移动事件
    touchmove(event) {
      let a = event.offsetX;
      // 获取y 坐标
      let b = event.offsetY;
      // console.log(a, b);
    },
    // 返回列表页
    fanhui() {
      this.$router.go(-1);
      // this.jump("renewal", this.$store.state.loginMessage);
    },
    // 进入发布页面
    fabu() {
      // this.jumps("issue", this.$store.state.loginMessage);
      this.$router.push("/issue");
    },
    // 评论点赞
    // id当条评论的id
    // num当条评论的点赞状态
    // type点赞的帖子还是评论0=帖子,1=评论
    // ci点赞的是主评论还是次级评论 1代表次级评论,0代表主评论
    // shu代表处于那条主评论
    // ceng代表具体属于哪条次级评论
    async pingDianzan(id, num, type, ci, shu, ceng) {
      if (this.verification(this.$route.path) == false) {
        return;
      }
      let a = 0;
      if (num == 0) {
        a = 1;
      } else {
        a = 0;
      }
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.artileCollect({
        cardid: id,
        type: 0,
        kind: type,
        port: 0,
        classify: a,
      })
        .then((res) => {
          if (res.code == 1) {
            // 1代表次级评论,0代表主评论
            // 次级评论不让他刷新手动修改
            if (ci == 1) {
              this.pingList[shu].child[ceng].liketag = a;
              this.loading.close();
              return;
            } else {
              this.pingList[shu].liketag = a;
              this.loading.close();
              return;
            }

            this.loading.close();
          } else {
            this.loading.close();
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    // 评论收藏
    async pingShoucang(id, num) {
      if (this.verification(this.$route.path) == false) {
        return;
      }
      let a = 0;
      if (num == 0) {
        a = 1;
      } else {
        a = 0;
      }
      let res = await API.artileCollect({
        cardid: id,
        type: 1,
        kind: 0,
        port: 0,
        classify: a,
      }).then((res) => {
        if (res.code == 1) {
          this.artCommetList();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 点赞帖子
    async dianzan(id, num) {
      if (this.verification(this.$route.path) == false) {
        return;
      }
      let a = 0;
      if (num == 0) {
        a = 1;
      } else {
        a = 0;
      }
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.artileCollect({
        cardid: id,
        type: 0,
        kind: 0,
        port: 0,
        classify: a,
      })
        .then((res) => {
          if (res.code == 1) {
            this.wanzhang.liketag = a;
            this.loading.close();
            // this.tiezi();
          } else {
            this.loading.close();
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    // 收藏帖子
    async shouchang(id, num) {
      if (this.verification(this.$route.path) == false) {
        return;
      }
      let a = 0;
      if (num == 0) {
        a = 1;
      } else {
        a = 0;
      }
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.artileCollect({
        cardid: id,
        type: 1,
        kind: 0,
        port: 0,
        classify: a,
      })
        .then((res) => {
          if (res.code == 1) {
            this.wanzhang.collectiontag = a;
            this.loading.close();
            // this.tiezi();
          } else {
            this.loading.close();
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    // 关注
    async guanz(id, num) {
      if (this.verification(this.$route.path) == false) {
        return;
      }
      let a = 0;
      if (num == 0) {
        a = 1;
      } else {
        a = 0;
      }
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.useFollow({
        useid: id,
        type: a,
      })
        .then((res) => {
          if (res.code == 1) {
            this.tiezi();
            this.artCommetList();
            this.loading.close();
          } else {
            this.loading.close();
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    // 登录后发帖
    dengfatie() {
      this.$store.state.path = this.$route.path;
      localStorage.setItem("path", this.$route.path);
      this.$router.push("/login");
    },
    // 次级评论
    async ciping(str) {
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });

      let buid = [];
      let fwb = str;
      this.buarr.forEach((item) => {
        let target = `@${item.name}`;
        var contains = this.richTextContains(fwb, target);
        if (contains == true) {
          buid.push(item.id);
        }
      });

      let res = API.artComment({
        cardid: this.$store.state.loginMessage.cardsalt,
        commentid: this.cijiid,
        departid: buid.toString(),
        port: 0,
        content: str,
        // images: this.cipngtu.toString(),
        images: [],
      })
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "发送成功,正在审核中!",
              center: true,
              type: "success",
            });
            this.loading.close();
            // 关闭弹框
            this.fayan = false;
            // 清空富文本
            this.$refs.ciji.qk();
            this.cipngtu = [];
            // this.artCommetList();
          } else {
            this.loading.close();
            this.$message({
              message: res.msg,
              center: true,
              type: "error",
            });
            // 关闭弹框
            this.fayan = false;
            // 清空富文本
            this.$refs.ciji.qk();
          }
          return false;
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    // 评论空校验
    kongwu() {
      // 没有任何输入
      let str = this.$refs.editor.GetTheHTML();
      // 创建一个新的DOM元素，例如一个div
      const tempDiv = document.createElement("div");

      // 设置div的内容为所提供的HTML
      tempDiv.innerHTML = str;

      // 检查div中是否有文字内容
      const hasText = tempDiv.textContent.trim().length > 0;

      // 检查div中是否有图片
      const hasImage = tempDiv.querySelector("img") !== null;

      // 返回是否有文字或图片的结果
      return hasText || hasImage;
      let c = ""; // 保存图片或视频路径
      // 没有任何输入
      // let str = this.$refs.editor.GetTheHTML();
      if (str == "") {
        return false;
      }
      // 匹配所有图片标签,获得地址
      let imgStrs = str.match(/<img.*?>/g);
      if (imgStrs != null) {
        c = imgStrs.map((url) => {
          return url.match(/\ssrc=['"](.*?)['"]/)[1];
        });
      }
      // console.log(urls, '图片');
      // 匹配所有视频标签,获得地址
      let videoStrs = str.match(/<video.*?>/g);
      if (videoStrs != null) {
        c = videoStrs.map((url) => {
          return url.match(/\ssrc=['"](.*?)['"]/)[1];
        });
      }

      var reg = new RegExp("<[^>]+>", "gi"); //过滤所有的html标签，不包括内容
      let a = str.replace(reg, "");
      let value = a.replace(/&nbsp;/gi, ""); //过略所有nbsp
      let b = value.replace(/\s*/g, ""); // 过略字符窜前后空格
      // 字符窜不为空可过
      if (b != "") {
        // console.log(b, "222");
        return true;
      } else {
        // 如果字符窜为空,则判断是否有图片或者视频
        if (c != "") {
          return true;
        } else {
          // 如果图片视频为空,不给过
          return false;
        }
      }
    },
    // 判断是否为64
    isBase64(str) {
      if (str.indexOf("data:") != -1 && str.indexOf("base64") != -1) {
        return true;
      } else {
        return false;
      }
    },
    baseti() {
      let cuo = 0;
      let a = this.$refs.editor.GetTheHTML();
      // 匹配所有图片标签,获得地址
      let imgStrs = a.match(/<img.*?>/g);
      if (imgStrs != null) {
        let urls = imgStrs.map((url) => {
          return url.match(/\ssrc=['"](.*?)['"]/)[1];
        });
        urls.forEach((item) => {
          // 将base64图片选出来
          if (this.isBase64(item) == true) {
            this.$alert(
              "您的图片存在格式问题,请删除后通过上传图片功能重新插入!",
              "警告",
              {
                confirmButtonText: "确定",
                callback: (action) => {},
              }
            );
            cuo = 1;
          }
        });
      }
      if (cuo == 0) {
        return false;
      } else {
        return true;
      }
    },
    richTextContains(html, text) {
      // 创建一个新的DOM解析器
      var parser = new DOMParser();
      var doc = parser.parseFromString(html, "text/html");

      // 使用textContent获取所有DOM节点的文本内容并进行检查
      return doc.body.textContent.includes(text);
    },
    // 发表评论
    async pinglun() {
      let aaa = window.localStorage.getItem("sendebang");
    // window.localStorage.removeItem('sendebang')
    if (aaa == undefined) {
      this.$store.state.path = this.$route.path;
      localStorage.setItem("path", this.$route.path);
      this.$store.state.mytie.fuwenben = this.fuwenben;

      MessageBox.confirm('您尚未登录，是否跳转登录?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).then(() => {
        this.$router.push("/login");
      }).catch((err) => {
      });
      return;
    }
    if(!this.canfabu){
      return
     }
      if (this.kongwu() == false) {
        this.$message({
          message: "信息不能为空!",
          type: "warning",
        });
        return;
      }
      // base64提示
      let cuo = this.baseti();
      if (cuo == true) {
        return;
      }
      this.canfabu=false
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });

      let buid = [];
      let fwb = this.$refs.editor.GetTheHTML();
      this.buarr.forEach((item) => {
        let target = `@${item.name}`;
        var contains = this.richTextContains(fwb, target);
        if (contains == true) {
          buid.push(item.id);
        }
      });

      await API.sensitive({
        info: this.$refs.editor.GetTheHTML(),
      })
        .then((res) => {
         
          if (res.code == 1) {
            let res = API.artComment({
              cardid: this.$store.state.loginMessage.cardsalt,
              commentid: "",
              departid: buid.toString(),
              port: 0,
              content: this.$refs.editor.GetTheHTML(),
              // images: this.pingtu.toString(),
              images: "",
            }).then((res) => {
              this.canfabu=true
              if (res.code == 1) {
                this.$message({
                  message: res.msg,
                  center: true,
                  type: "success",
                });
                this.cishu = this.cishu + 1;
                this.artCommetList();
              } else {
                this.$message({
                  message: res.msg,
                  center: true,
                  type: "error",
                });
              }
              this.loading.close();
            });
          } else {
            this.loading.close();
            this.canfabu=true
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    // 帖子详情
    async tiezi() {
      let res = await API.articleInfo({
        cardid: this.$store.state.loginMessage.cardsalt,
      }).then((res) => {
        // console.log(res, "22");
        if (res.code == 1) {
          // 判读是否需要打开蒙层
          // 0=不是自己的，1= 自己的
          if (res.data.closewtag == 0 && res.data.status == "待审核") {
            this.mengnum = 1;
            this.muqian = "待审核";
            document.title = '盐都区网络发言人公共服务平台';
          }else if (res.data.closewtag == 0 && res.data.is_open == 0) {
            this.mengnum = 1;
            this.muqian = "未公开";
            document.title = '盐都区网络发言人公共服务平台';
          }else if (res.data.closewtag == 0 && res.data.status == '审核不通过') {
            this.mengnum = 1;
            this.muqian = "审核不通过";
            document.title = '盐都区网络发言人公共服务平台';
          }else{
            this.buarr = res.data.depart;
          this.$store.state.buarr = res.data.depart;
          // 重新加载富文本
          this.cishu = this.cishu + 1;

          this.wanzhang = res.data;
          document.title = this.wanzhang.title;
          this.zid = res.data.useid;
          }
        
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 获得富文本
    chang(res) {
      this.fuwenben = res;
      // console.log(this.fuwenben, "22323");
    },
    // 切换条目
    handleSizeChange(val) {
      this.zongye = val;
      this.artCommetList();
      // console.log(`每页 ${val} 条`);
    },
    // 切换页码
    handleCurrentChange(val) {
      this.page = val;
      this.artCommetList();
      // console.log(`当前页: ${val}`);
    },
  },
  // 销毁定时器
  beforeDestroy() {
    clearTimeout(timess);
  },
};
</script>

<style scoped lang="scss">
.details {
  width: 1200px;
}

.biao {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  margin: 16px 0 22px 0;

  .zuojian {
    width: 18px;
    height: 18px;
    margin: 0 3px;
  }

  .yanse {
    color: #0653ac;
  }
}

.chehui {
  ::v-deep(.el-button--primary) {
    width: 110px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    border: 0px solid red;
    margin-right: 17px;
  }

  ::v-deep(.el-button--info) {
    // width: 82px;
    height: 44px;
    background: #86898d;
    border-radius: 2px;
  }

  .che {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #2670c5;
    letter-spacing: 0;
    font-weight: 400;

    .huitui {
      width: 15.69px;
      height: 13.07px;
      margin-right: 6.15px;
    }
  }
}

// 帖子详情
.tiezi {
  background: #f4f8fd;
  border: 1px solid #d6dbe3;
  width: 100%;
  margin-top: 16px;

  .xianq {
    // border-bottom: 4px solid #F1F1F1;
  }

  .xian_head {
    min-height: 58px;
    background: #ffffff;
    border-bottom: 4px solid #f1f1f1;

    .zuo {
      width: 227px;
      // height: 100%;
      border-right: 3px solid #d6dbe3;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #484c51;
      letter-spacing: 0;
      font-weight: 400;

      .mr33 {
        margin-right: 33px;
      }

      .yanse {
        color: #fb8a2b;
      }
    }

    .you {
      padding: 5px 23px;
      width: calc(1200px - 227px);
      .zixu {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        letter-spacing: 0;
        font-weight: 400;
        // white-space: nowrap;

        .zi {
          color: #34348f;
        }

        .lv {
          color: #00a576;
        }

        .hong {
          color: #ff5637;
        }
      }

      .danwei {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #51565c;
        letter-spacing: 0;
        font-weight: 400;
        // white-space: nowrap;
      }

      .biaoti {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #18191a;
        letter-spacing: 0;
        font-weight: 500;
      }

      .shouli {
        width: 61px;
        height: 19.7px;
        display: inline-block;
        vertical-align: sub;
        // margin-left: 5px;
      }
    }
  }

  .louzhu {
    background: #ffffff;
    min-height: 364px;
    border-bottom: 4px solid #f1f1f1;

    .zuo {
      width: 227px;
      border-right: 3px solid #d6dbe3;
      padding: 40px 0 0 0;
      background: #f4f8fd;
      flex-direction: column;
      align-items: center;
      position: relative;

      .fyrbiaoji {
        position: absolute;
        left: 0;
        top: 0;
        width: 80px;
        height: 25px;
      }

      .louzhubiaoji {
        position: absolute;
        right: -1px;
        top: 0;
        width: 43.73px;
        height: 40.06px;
      }

      .touxinag {
        width: 110px;
        height: 110px;
        background: #fff;
        border: 1px solid #d6dbe3;
        padding: 5px;
        // cursor: pointer;
      }

      .ming {
        margin-top: 8px;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #34348f;
        letter-spacing: 0;
        font-weight: 500;
        white-space: nowrap;
      }

      .ziliao {
        margin-top: 17px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #27262f;
        letter-spacing: 0;
        font-weight: 400;

        .fontone {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #484c51;
          letter-spacing: 0;
          font-weight: 400;
        }

        .xian {
          width: 1px;
          height: 16px;
          border-left: 1px solid #d6dbe3;
          margin: 0 30px;
        }
      }

      .mt15 {
        margin-top: 15px;
      }

      .dian {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-right: 5px;
        background: #595e64;
      }

      .fontwo {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #484c51;
        letter-spacing: 0;
        font-weight: 400;
      }

      .guanzhu {
        margin-left: 17px;
        width: 62px;
        height: 26px;
        border-radius: 17px;
        border: 1px solid #868d9d;
        text-align: center;
        line-height: 26px;
        cursor: pointer;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        color: #858ea0;
        letter-spacing: 0;
        font-weight: 500;
      }

      .yiguan {
        margin-left: 17px;
        width: 62px;
        height: 26px;
        border-radius: 17px;
        border: 1px solid #fb8a2b;
        text-align: center;
        background: #fb8a2b;
        line-height: 26px;
        cursor: pointer;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        color: #fff;
        letter-spacing: 0;
        font-weight: 500;
      }
    }

    .you {
      width: calc(1200px - 227px);
      .fabuxinxi {
        width: 100%;
        height: 35px;
        padding: 0 19px 0 32px;
        border-bottom: 1px dashed transparent;
        background: linear-gradient(#fff, #fff) padding-box,
          repeating-linear-gradient(
            -45deg,
            #d6dbe3 0,
            #d6dbe3 0.3em,
            #fff 0,
            #fff 0.6em
          );
        /* 0.3em设置虚线点的宽度或长度；0.6em设置虚线点的间距 */
      }

      .fontone {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #484c51;
        letter-spacing: 0;
        font-weight: 400;
      }

      .fontTwo {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #27262f;
        letter-spacing: 0;
        font-weight: 400;
      }

      .wenzhanzhuti {
        padding: 19px 25px 21px 33px;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
      }

      .mt33 {
        margin-top: 33px;
      }

      .guangfang {
        padding: 7px 26px;
        background: #e8effa;
        border-radius: 2px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #0653ac;
        letter-spacing: 0;
        font-weight: 400;
      }

      .caozuo {
        margin-top: 65px;
        justify-content: space-between;
        flex-direction: column;
      }

      .bianju {
        margin-left: 20px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #484c51;
        letter-spacing: 0;
        font-weight: 400;

        .icon {
          width: 18px;
          height: 18px;
          margin-right: 3px;
        }

        .yanse {
          color: #2670c5;
        }
      }
    }
  }
}

.yema {
  margin: 30px 0 30px 0;
  display: flex;
  justify-content: end;

  ::v-deep(.el-pager) {
    .number {
      background: #fff;
      border: 0.5px solid #d6dbe3;
    }
  }

  ::v-deep(.el-pagination__jump) {
    margin-left: 0 !important;
  }
}

.xian {
  width: 100%;
  height: 1px;
  border-bottom: 4px solid #f1f1f1;
  margin-bottom: 9px;
}

.fwb {
  margin-bottom: 10px;

  .huifu {
    width: 227px;
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #36383a;
    letter-spacing: 0;
    font-weight: 400;
    // margin-right: 28px;
    background: #f4f8fd;
    text-align: center;
    padding-top: 20px;
  }

  .wb {
    flex: 1;
    position: relative;

    .weideng {
      position: absolute;
      width: 100%;
      height: 100%;
      // background: #000;
      left: 0;
      top: 0;
      z-index: 10002;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.zanshi {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
}

.act {
  border-bottom: 0px solid red !important;
}

.yi {
  position: fixed;
  width: 650px;
  height: 350px;
  border: 5px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  left: calc(50% - 325px);
  top: calc(50% - 175px);
  z-index: 100000;

  .yidongquyu {
    width: 100%;
    height: 36px;
    // cursor: move;
    align-items: center;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;
    padding: 0 10px;
    justify-content: space-between;

    .guanbi {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
}

:deep(.el-dialog) {
  width: 410px;
  border-radius: 17px;
  overflow: hidden;
}

:deep(.el-dialog__header) {
  padding: 0px;
}

:deep(.el-dialog__headerbtn) {
  right: 10px !important;
  top: 10px !important;
}

:deep(.el-dialog__body) {
  padding: 0;
}

.lv {
  background: #04af23 !important;
}

.lvz {
  color: #04af23 !important;
}

.huiping {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #18191a;
  letter-spacing: 0;
  font-weight: 500;
  margin: 10px 0;
}

.hz {
  color: #c90e0e !important;
}

.heqiang {
  width: 227px;
}

.btn {
  // margin-top: 10px;

  ::v-deep(.el-button--primary) {
    background: #1c69c6;
    border-radius: 2px;
    width: 120px;
    height: 44px;
    border: 0px solid red;
  }
}

.fujian {
  display: flex;

  .fu {
    margin-right: 5px;
  }

  .papazuo {
    display: flex;
    // width: 500px;
    // flex-wrap:wrap;
    flex-direction: column;

    .lou {
      margin-bottom: 5px;
      // margin-bottom: 5px;
      color: #0653ac;
      cursor: pointer;
    }
  }
}

.tuzhan {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .tupian {
    width: 100%;
    // height: 200px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    :deep(.img) {
      width: 640px;
      // height: 100%;
    }
  }

  .tunei {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tupianwen {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #484c51;
    letter-spacing: 0;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      color: #1c69c6;
    }
  }
}

.shizhan {
  width: 100%;
  padding: 0 20px;
  // height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 20px;

  .tupianwen {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #484c51;
    letter-spacing: 0;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      color: #1c69c6;
    }
  }

  .shipins {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .shipin {
    width: 640px;
    height: 360px;
    margin-bottom: 5px;
  }
}

.tufu {
  position: relative;

  .fugai {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.xiabtn {
  margin-left: 5px;

  &:hover {
    color: #c90e0e;
  }
}

.huifu__ {
  width: 100%;
  padding: 15px 0;
  min-height: 30px;
  font-size: 18px;
  color: rgb(153, 153, 153);
  margin-bottom: 20px;
  background-color: #f9f9f9;
}

::v-deep(.el-popover) {
  min-width: fit-content !important;
}

.xuanfu {
  min-width: fit-content !important;

  .xiazai {
    text-align: center;
    cursor: pointer;
  }
}

.ful {
  margin-top: 40px;

  .wenjianming {
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #1c69c6;
    letter-spacing: 0;
    line-height: 21px;
    font-weight: 500;
    cursor: pointer;

    .hov:hover {
      color: #ff0424 !important;
    }

    .xiazi {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #7b8391;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}

.pint {
  margin-top: 20px;

  .pingtu {
    width: 320px;
    height: 180px;
    cursor: pointer;
  }
}

.huifu__ {
  padding: 10px 20px;
  box-sizing: border-box;
}

// 富文本上传弹框
.shangchua {
  z-index: 100003;
  position: fixed;
  left: calc(50% - 292px);
  top: 20%;
  width: 584px;
  // height: 500px;
  background: #fff;
  border-radius: 4px;
  padding: 10px 0 20px 0;
  box-sizing: border-box;
  border: 7px solid rgba(0, 0, 0, 0.2);

  .tou {
    border-bottom: 1px solid #eeeef1;
    position: relative;
    width: 100%;
    height: 30px;
    padding: 0 20px 0 0;
    box-sizing: border-box;

    .closeg {
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: #ff1432;
      }
    }
  }

  .fuqilai {
    position: absolute;
    left: 10px;
    top: 0px;
  }

  .shangchuan {
    padding: 0px 20px;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid #eeeef1;
    background: #fff;
    line-height: 30px;
    cursor: pointer;
    text-align: center;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #2c2a36;
    letter-spacing: 0;
    font-weight: 400;
    background: #f6f6f6;
  }
}

.shenti {
  width: 100%;
  padding: 10px 0px 10px 0;
  box-sizing: border-box;

  // overflow: hidden;

  .tupianka {
    width: 100%;
    min-height: 50px;
  }

  ::v-deep(.el-button--small) {
    padding: 5px 8px;
    box-sizing: border-box;
    background: #ff5131;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #fff;
    letter-spacing: 0;
    text-align: justify;
    font-weight: 400;
    border-radius: 10px;
    width: 88px;
    white-space: nowrap;
    margin-left: 20px;
    cursor: pointer;
    border: 0px;
  }

  .hbss {
  }
}

.dibuka {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0 20px;
  box-sizing: border-box;
  margin-top: 10px;

  .jinggao {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;
  }

  .zy {
    color: #f26c4f !important;
  }

  .kabtn {
    width: 120px;
    height: 44px;
    background: #1c69c6;
    border-radius: 2px;
    line-height: 44px;
    text-align: center;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    cursor: pointer;
  }
}

.meixia {
  border-bottom: 0px !important;
  border-radius: -4px;
  background: #fff !important;
}

.kazhan {
  width: 100%;
  flex-wrap: wrap;
  padding: 0 0 0 10px;
  box-sizing: border-box;
  margin-top: 20px;
  max-height: 400px;
  overflow-y: auto;
}

.katu {
  width: 110px;
  margin: 0 20px 10px 0;

  .tubufen {
    width: 100%;
    height: 110px;
    display: flex;
    align-items: center;
    border: 1px solid #eeeef1;

    img {
      max-height: 110px;
      // border: 1px solid #EEEEF1;
    }

    video {
      max-height: 110px;
    }
  }

  .tushan {
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #27262f;
    letter-spacing: 0;
    font-weight: 400;

    &:hover {
      color: #ff1432 !important;
    }
  }
}

.kafijian {
  display: flex;
  margin-bottom: 10px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  padding: 0 0 0 10px;
  box-sizing: border-box;

  .wenshan {
    text-align: center;
    cursor: pointer;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #27262f;
    letter-spacing: 0;
    font-weight: 400;
    margin-left: 15px;

    &:hover {
      color: #ff1432 !important;
    }
  }
}

.zhe {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}
.wen {
  margin-left: 20px;
  align-items: center;
}
.mings {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.lan {
  color: #043e81;
}
.cijihuifu {
  width: 100%;
  border: 1px solid #f0f1f2;
  padding: 4px 15px 14px;
  box-sizing: border-box;
  margin-top: 10px;
  background: #f7f8fa;
  ::v-deep(.el-textarea) {
    margin-top: 10px;
  }
  .fabtn {
    width: 51px;
    height: 24px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    background: #3a83f1;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
    &:hover {
      background: #27b3ff;
    }
  }
}
.cihang {
  width: 100%;
  margin-bottom: 18px;
  display: flex;
  .touxiang {
    width: 48px;
    height: 48px;
    margin-right: 10px;
    img {
      width: 48px;
      height: 48px;
    }
  }
  .ciming {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #34348f;
    letter-spacing: 0;
    font-weight: 500;
    margin-right: 5px;
  }
  .cihuinei {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;
  }
  .xibain {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
}
.gengduo {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: #707070;
  line-height: 22px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shuobtn {
  padding: 4px 8px;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  cursor: pointer;
  color: #333;
  border: 1px solid #707070;
  border-radius: 8px;
}
::v-deep(.el-textarea__inner::placeholder) {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #484c51;
  letter-spacing: 0;
  font-weight: 400;
}
::v-deep(.wenzhan) {
  p {
    img {
      max-width: 100% !important;
    }
  }
}
.yangshi {
  font-family: PingFangSC-Semibold;
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  margin-left: 5px;
  .zy {
    color: #f26c4f !important;
  }
}
.beizhu {
  color: #66ccff !important;
  font-weight: 700;
}
.xiaoshijian {
  margin-top: 8px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #484c51;
  letter-spacing: 0;
  font-weight: 400;
}
.mengcneg {
  position: absolute;
  left: -50%;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 10003;
  background-color: rgba(255, 255, 255, 0.1); /* 半透明背景 */
  backdrop-filter: blur(10px); /* 背景模糊 */
  -webkit-backdrop-filter: blur(10px); /* 兼容老版本 Safari */
  display: flex;
  justify-content: center;
}
.tankuang {
  position: absolute;
  left: calc(50% - 250px);
  top: 50px;
  width: 500px;
  height: 250px;
  z-index: 10004;
  background: #fff;
  border-radius: 24px;
  box-shadow: 0 8px 27px 0 rgba(69, 78, 107, 0.38);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .zhonjian {
    font-size: 18px;
    color: #303133;
    margin-bottom: 30px;
  }
  ::v-deep(.el-button--primary) {
    width: 30%;
  }
}
.jizhuduxiang {
  font-size: 16px;
  cursor: pointer;
}
.antebieren {
  margin-left: 10px;
  color: #339933;
  display: flex;
  align-items: center;
}
.gbclos {
  font-size: 16px;
  color: #000;
}
</style>